// @flow

import { ThemeProvider } from 'emotion-theming'
import { withPrefix } from 'gatsby'
import * as React from 'react'
import styled from '@emotion/styled'

import { Button, Footer, Navigation, Icon, text } from '@toggl/ui'
import { useSession, useAuth } from '@toggl/auth'
import { colors, media, themes } from '@toggl/style'
import type { NavigationType } from '../types'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
// $FlowFixMe
import yamlData from '../../data/track/layout.yml'

type Props = {
  Hero: React.ComponentType<any>,
  children?: React.Node,
}

const Li = ({ children }) => <li>{children}</li>

const planWidth = css`
  width: 111px;
  svg {
    width: 100%;
  }
`

const hireWidth = css`
  width: 104px;
  svg {
    width: 100%;
  }
`

const IconPlan = () => {
  return (
    <div css={planWidth}>
      <Icon.Plan />
    </div>
  )
}

const IconHire = () => {
  return (
    <div css={hireWidth}>
      <Icon.Hire />
    </div>
  )
}

const trackWidth = css`
  width: 160px;
  svg {
    width: 100%;
  }
`
const IconTrack = () => {
  return (
    <div css={trackWidth}>
      <Icon.Track />
    </div>
  )
}

const LayoutTrack = ({ children, Hero }: Props) => {
  const navigation: NavigationType = yamlData.navigation
  const [loggingOut, setLoggingOut] = React.useState(false)
  const [hasSession, session] = useSession()
  const [ready, auth] = useAuth()

  const onLogout = () => {
    // Retry until it's ready
    if (!ready) {
      setTimeout(onLogout, 1000)
    }

    setLoggingOut(true)
    auth
      .logout()
      .then(() => {
        session.destroy()
        setLoggingOut(false)
        return
      })
      .catch(() => {
        setLoggingOut(false)
      })
  }
  return (
    <ThemeProvider theme={themes.track}>
      <MiniBar>
        <MiniBarLinksSection>
          <MiniBarAnchor href="#">
            Products <Icon.ArrowDownThin />
          </MiniBarAnchor>

          <MiniBarAnchor href={'https://toggl.com/blog/'}>Blog</MiniBarAnchor>
          <MiniBarLink to={'/mission/'}>Our mission</MiniBarLink>
          <MiniBarLink to={'/jobs/'}>Working at Toggl</MiniBarLink>
        </MiniBarLinksSection>
      </MiniBar>
      {!!Hero && (
        <Hero>
          <Navigation.Header
            contents={{
              cta: (
                <Navigation.MenuContent
                  title={navigation.cta.title}
                  description={navigation.cta.subtitle}
                >
                  <Navigation.ProductsGroup>
                    {navigation.cta.items?.map((item, index) => {
                      return (
                        <Link key={index} to={item.url}>
                          <Navigation.Product
                            key={index}
                            img={withPrefix(item.image)}
                            title={item.title}
                            description={item.subtitle}
                          />
                        </Link>
                      )
                    })}
                  </Navigation.ProductsGroup>
                </Navigation.MenuContent>
              ),
              products: (
                <Navigation.MenuContent
                  title={navigation.products.title}
                  description={navigation.products.subtitle}
                >
                  <Navigation.ProductsGroup>
                    {navigation.products.items?.map((item, index) => {
                      return (
                        <Link key={index} to={item.url}>
                          <Navigation.Product
                            key={index}
                            img={withPrefix(item.image)}
                            title={item.title}
                            description={item.subtitle}
                          />
                        </Link>
                      )
                    })}
                  </Navigation.ProductsGroup>
                </Navigation.MenuContent>
              ),
            }}
            logoWidth={['122px', '168px']}
            Logo={() => (
              <Link to={'/track/'}>
                <Icon.Track />
              </Link>
            )}
            login={
              hasSession ? (
                <Button.Secondary disabled={loggingOut} onClick={onLogout}>
                  Logout
                </Button.Secondary>
              ) : (
                <Link to={'/track/login/'}>
                  <Button.Secondary>Login</Button.Secondary>
                </Link>
              )
            }
            Cta={() => (
              <Button.SecondaryTrack data-expandable="true">
                Try Toggl
              </Button.SecondaryTrack>
            )}
          >
            <a data-expandable="true" data-content-key="products">
              Why Track?
            </a>
            <Link to={'/track/features/'}>Features</Link>
            <Link to={'/track/pricing/'}>Pricing</Link>
            {/** TODO */}
            <Link to={'/track/integrations/'}>Apps & Integrations</Link>
          </Navigation.Header>
        </Hero>
      )}

      <MainContent>
        <Body>{children}</Body>
        <Footer.Wrapper
          Link={Link}
          logo={<IconTrack />}
          products={[
            <>
              <IconPlan />
              <span>Beautifully simple project planning</span>
            </>,
            <>
              <IconHire />
              <span>Intelligent candidate screening</span>
            </>,
          ]}
        >
          <Footer.List title="Product">
            <Li>
              <Link to={'/track/features/'}>Features</Link>
            </Li>
            <Li>
              <Link to={'/track/pricing/'}>Pricing</Link>
            </Li>
            <Li>
              {/** TODO */}
              <Link to={'/track/integrations/'}>Apps & Integrations</Link>
            </Li>
            <Li>
              {/** TODO */}
              <Link to={'/track/case-studies/'}>Case Studies</Link>
            </Li>
            <Li>
              {/** TODO */}
              <Link to={'/track/api/'}>API</Link>
            </Li>
          </Footer.List>
          <Footer.List title="Download">
            <Li>
              <Link to={'/track/mobile-apps/'}>Mobile Apps</Link>
            </Li>
            <Li>
              <Link to={'/track/desktop-apps/'}>Desktop APps</Link>
            </Li>
            <Li>
              <Link to={'/track/browser-extensions/'}>Browser Extensions</Link>
            </Li>
          </Footer.List>
          <Footer.List title="Help">
            <Li>Mobile Apps</Li>
            <Li>Desktop Apps</Li>
            <Li>Browser Extensions</Li>
          </Footer.List>
          <Footer.List title="Resources">
            <Li>
              <a
                href={'https://toggl./com/blog/'}
                target="_blank"
                rel="noreferrer"
              >
                Blog
              </a>
            </Li>
            <Li>Business Resources</Li>
            <Li>Productivity Resources</Li>
            <Li>eBooks</Li>
            <Li>Webinars</Li>
            <Li>Media Kit</Li>
          </Footer.List>
        </Footer.Wrapper>
      </MainContent>
    </ThemeProvider>
  )
}

const MiniBarLink = styled(Link)`
  ${text.paragraph4};
  cursor: pointer;
  font-size: 0.75rem;
  color: ${colors.darkPurple};
`

const MiniBarAnchor = styled.a`
  ${text.paragraph4};
  cursor: pointer;
  font-size: 0.75rem;
  color: ${colors.darkPurple};
`

const MiniBarLinksSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 330px;
  margin-right: 42px;
  align-items: center;
`

const MiniBar = styled.div`
  display: none;
  ${media.mq[1]} {
    display: flex;
    justify-content: flex-end;

    height: 31px;
    background-color: ${(p) => p.theme.primary};
  }
`

const MainContent = styled.div``
const Body = styled.div``

export default LayoutTrack
